import KatalLogger from "@katal/logger";
import DataHandler from "../handler/DataHandler";
import Constants from "../constants/Constants";
import DeviceDetailsHelper from "../helper/DeviceDetailsHelper";
import { v4 as uuidv4 } from 'uuid';

export default {
    log : null,
    async setLogger(loggerURL, stage) {
        this.log = new KatalLogger({
            url: loggerURL,
            decodeStackTrace : true,
            context: {
                userId: DataHandler.getUserId(),
                country: DataHandler.getCountry(),
                deviceIMEI: DeviceDetailsHelper.getDeviceIMEI(),
                stationCode: DataHandler.getStationCode(),
                deviceChromeVersion: DeviceDetailsHelper.getChromeVersion(),
                dolphinSessionId: uuidv4()
            },
            logToConsole: (stage === Constants.Stage.BETA|| stage === Constants.Stage.GAMMA)
        });
        this.log.addErrorListener();
    },
    async getLogger() {
        return this.log;
    }
}