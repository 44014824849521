import DataHandler from "./DataHandler";
import UAASAccessor from "../accessor/UAASAccessor";

export default {
    Features : {
        VOLUMETRIC_SCAN: "VolumetricScan",
        INDUCT_LABEL_PRINTING: "InductLabelPrinting",
        AISLE_FREE_PACKAGE_PICKING: "AisleFreePackagePicking",
        PICKING_MULTIPLE_BAG: "PickingMultipleBag",
        PICKING_MULTIPLE_BAG_WITH_SETTING: "PickingMultipleBagWithSetting",
        DECOUPLED_PICK_STAGE: "DecoupledPickStage",
        MANUAL_TRACKING_ID_HELP_ENABLED: "ManualTrackingIdHelpEnabled",
        STORE_DEBRIEF_ENABLED: "StoreDebriefEnabled",
        PHONE_NUMBER_SEARCH_ENABLED: "PhoneNumberSearchEnabled",
        RECEIVE_ENABLED: "ReceiveEnabled",
        RTS_HIGH_VALUE_PACKAGE_WARNING: "RTSHighValuePackageWarning",
        RTS_RESCHEDULE_VISIBILITY: "RTSRescheduleVisibility",
        RTS_FIXIT_INTEGRATION: "RTSFixitIntegration",
        RTFC_PACKAGE_DEPART: "RTFCPackageDepart",
        HIDE_REQUEST_STAGE_LIST: "HideRequestTaskList",
        ASSIGN_TO_STAGE: "AssignToStage",
        SCAN_TO_STAGE: "ScanToStage",
        MIXED_CART_FLASH_SCREEN: "MixedCartFlashScreen",
        STAGE_SERVICE_ENABLED: "StageServiceEnabled",
        PICK_ITEM_SCAN_EVENT_ENABLED:"PickItemScanEventEnabled"
    },

    isFeatureEnabled(featureName) {
        return DataHandler.userFeatures.includes(featureName);
    },

    /**
     * Retrieves G2S2 features enabled in given stage and region
     * from UserAccountAndAttributesService
     * @param stage
     * @param region
     * @returns {Promise<void>}
     */
    async getFeatures(stage, region) {
        //If features are already retrieved skip this call.
        if (DataHandler.userFeatures.length !== 0) {
            return;
        }
        const modulesAndFeatures = await UAASAccessor.getModulesAndFeatures(stage, region);
        DataHandler.setUserFeatures(modulesAndFeatures.features.map((feature) => {
            return feature.featureName
        }))
    }
};
