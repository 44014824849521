import React, {Component} from "react";
import Column from "@amzn/meridian/column";
import PropTypes from "prop-types";
import Input from "@amzn/meridian/input";
import HeaderView from "../HeaderView";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";

class ScanWithInputView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    handleChange = (key) => {
        this.setState({value: key});
    }

    handleSubmit = (event) => {
        if (event.keyCode === 13)  // the enter key code
        {
            this.props.onSubmit(this.state.value);
            // blur the input box to remove the focus
            event.target.blur();
            this.setState({value: ""});
        }
    }

    render() {
        const {title, description, button} = this.props
        const headerData = {
            primaryTitle: {
                title: title
            },
            secondaryTitle: {
                title: description,
                type: "Bold",
                size: "Large"
            }
        }
        return (
            <Column heights={["fit", "fit", "fill"]} height={"100%"} spacingInset="medium">
                <Column width="100%" widths="fill" alignmentHorizontal="center">
                    <HeaderView {...headerData} />
                </Column>
                <Input autoFocus={true} autoFill={false} size="small" value={this.state.value} onChange={this.handleChange} type="text"
                       onKeyDown={this.handleSubmit}/>
                {this.props.checkbox &&
                <Column alignmentHorizontal="left">
                    <Checkbox checked={this.props.checkbox.checked} onChange={this.props.checkbox.onChange}>
                        {this.props.checkbox.label}
                    </Checkbox>
                </Column>
                }
                {button &&
                <Column width="100%" alignmentVertical="bottom">
                    <Button onClick={() => this.props.onSubmit(this.state.value)}
                            size="large" type="primary">{button.title}</Button>
                </Column>
                }
            </Column>
        )
    }
}


ScanWithInputView.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    button: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }),
    checkbox: PropTypes.shape({
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    })
}

export default ScanWithInputView;