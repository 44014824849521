import React from 'react';
import Box from "@amzn/meridian/box";
import PropTypes from "prop-types";
import './appscanner.css';

const APP_SCANNER_SUPPORTED = "app-scanner-supported";

export default function AppScanner(props) {

    const ref = React.useRef(null);

    React.useEffect(() => {
        // debugger;
        setupScanner();
        document.addEventListener('focus', setupScanner);
        return () => {
            document.removeEventListener('focus', setupScanner);
        }
    }, [props.scannerEnabled]);

    const keyDown = (e) => {
        if (e.keyCode === 13) {
            props.onScan(ref.current.value);
            ref.current.value = "";
        }
    }

    const setupScanner = () => {
        ref.current.readOnly = true;
        // update status if enabled is passed
        if (props.scannerEnabled) {
            setTimeout(() => {
                ref.current.focus();
                ref.current.readOnly = false;
            });
        } else {
            ref.current.value = "";
            ref.current.blur();
        }
    }

    /**
     * Identifies if we have to bring the focus back to the scanner
     * or adds blur event on the newly focused input element
     */
    const onBlur = () => {
        if (!props.scannerEnabled) {
            ref.current.readOnly = true;
            return;
        }
        setTimeout(() => {
            const activeElement = document.activeElement;
            if (activeElement && activeElement.tagName.toLowerCase() === "input") {
                if (!activeElement.getAttribute(APP_SCANNER_SUPPORTED)) {
                    console.log("adding blur event");
                    activeElement.setAttribute(APP_SCANNER_SUPPORTED, true);
                    activeElement.addEventListener("blur", onBlur);
                }
            } else {
                setupScanner();
            }
        });
    }

    return <Box className={"appScanner"}>
        <input id="app-scanner" autoFocus={true} autoComplete={"off"}
               ref={ref}
               readOnly type="text" onKeyDown={keyDown}
               onBlur={onBlur}/>
    </Box>;
}

AppScanner.propTypes = {
    scannerEnabled: PropTypes.bool.isRequired,
    onScan: PropTypes.func.isRequired
}