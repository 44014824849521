import MeshClient from "../client/MeshClient";
import MeshEvents from "../constants/MeshEvents";
import {postMessagePromised} from "../handler/MeshEventHandler";

export default {
    async getAccessToken() {
        const accessTokenRequest = { eventName: MeshEvents.MESH_EVENTS.GET_TOKEN };
        return await postMessagePromised(accessTokenRequest, MeshEvents.MESH_EVENTS.GET_TOKEN);
    },
    printAveryLabel(salOutput, tag) {
        const payload = JSON.stringify({ value : salOutput, tag : tag });
        const printLabelRequest = { eventName: MeshEvents.MESH_EVENTS.PRINT_SAL, payload: payload };
        MeshClient.sendMessageToServer(JSON.stringify(printLabelRequest));
    },
    setSalFormat(salFormat, tag) {
        const payload = JSON.stringify({ value : salFormat, tag : tag});
        const setSalFormatRequest = { eventName: MeshEvents.MESH_EVENTS.SET_SAL_FORMAT, payload: payload};
        MeshClient.sendMessageToServer(JSON.stringify(setSalFormatRequest));
    },
    exitModule() {
        const exitModuleRequest = { eventName: MeshEvents.MESH_EVENTS.DESTROY};
        MeshClient.sendMessageToServer(JSON.stringify(exitModuleRequest));
    },
    sblSourceScan(scanContent, scanContentType) {
        const payload = JSON.stringify({ scanContent : scanContent, scanContentType : scanContentType});
        const sblSourceScanRequest = { eventName: MeshEvents.MESH_EVENTS.SBL_SOURCE_SCAN, payload: payload };
        MeshClient.sendMessageToServer(JSON.stringify(sblSourceScanRequest));
    },
    sblDestinationScan(scanContent, containerId, scanContentType) {
        const payload = JSON.stringify({ scanContent : scanContent, containerId: containerId,
            scanContentType : scanContentType});
        const sblDestinationScanRequest = { eventName: MeshEvents.MESH_EVENTS.SBL_DESTINATION_SCAN, payload: payload };
        MeshClient.sendMessageToServer(JSON.stringify(sblDestinationScanRequest));
    },
    async connectZebra(macAddress){
        const connectZebraRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_CREATE_CONNECTION, payload: macAddress};
        return await postMessagePromised(connectZebraRequest, MeshEvents.MESH_EVENTS.ZEBRA_CREATE_CONNECTION);
    },
    closeConnectionZebra(){
        const closeConnectionZebraRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_CLOSE_CONNECTION };
        MeshClient.sendMessageToServer(JSON.stringify(closeConnectionZebraRequest));
    },
    async printZebra(printOutput){
        const printZebraRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_PRINT_LABEL, payload: printOutput};
        return await postMessagePromised(printZebraRequest, MeshEvents.MESH_EVENTS.ZEBRA_PRINT_LABEL);
    },
    async getZebraStatus(){
        const zebraStatusRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_STATUS};
        return await postMessagePromised(zebraStatusRequest, MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_STATUS);
    },
    async getZebraResolution(){
        const zebraResolutionRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_RESOLUTION};
        return await postMessagePromised(zebraResolutionRequest, MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_RESOLUTION);
    },
};